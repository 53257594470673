// custom typefaces
import 'typeface-montserrat';
import 'typeface-merriweather';
// Highlighting for code blocks
import 'prismjs/themes/prism.css';
import './src/styles/marketo.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './src/styles/fonts.css';
export const shouldUpdateScroll = ({ routerProps }) => {
    return false;
  };
